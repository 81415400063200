<template>
  <div class="app-container app-theme-white body-tabs-shadow">
    <div class="app-container">
      <div class="h-100 bg-plum-plate bg-animation">
        <div
          style="
            position: absolute;
            width: 100%;
            height: 100%;
            background-size: cover;
            opacity: 0.3;
          "
          :style="{
            'background-image': 'url(/originals/writing.jpg)',
          }"
        ></div>
        <div class="d-flex h-100 justify-content-center align-items-center">
          <div
            class="
              app-login-box
              col-md-8
              d-flex
              align-items-center
              justify-content-center
              flex-column
            "
          >
            <div class="app-logo-inverse mb-3" :class="logoClass"></div>
            <div class="modal-dialog w-100 mx-auto">
              <div class="modal-content">
                <form @submit.prevent="login">
                  <div
                    class="
                      modal-body
                      flex-column
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <div class="h5 modal-title text-center">
                      <h4 class="mt-2 mb-0">
                        <div >Mis recibos digitales</div>
                      </h4>
                      <h4 class="mt-0 mb-2 col-10 offset-1">
                        <div>{{sistema}}</div>
                      </h4>
                        <hr class="my-1">
                      <h4 class="mt-1">
                        <span>Ingrese sus datos.</span>
                      </h4>
                    </div>
                    <div class="form-row">
                      <float-label
                        label="CUIL:"
                        clase="col-12"
                        id="usuario"
                        placeholder="CUIL"
                        type="text"
                        classInput="form-control"
                        v-model="usuario.cuil"
                        :required="enviado && !$v.usuario.cuil.required"
                      >
                      </float-label>
                      <float-label
                        label="Contraseña:"
                        clase="col-12"
                        id="password"
                        placeholder="Contraseña"
                        type="password"
                        classInput="form-control"
                        v-model="usuario.password"
                        :required="enviado && !$v.usuario.password.required"
                      >
                      </float-label>
                    </div>
                    <h6 class="mb-0">
                      ¿No tienes cuenta?
                      <router-link to="/register" class="text-primary"
                        >Regístrese aquí</router-link
                      >
                    </h6>
                  </div>
                  <div class="modal-footer clearfix">
                    <div class="float-left">
                      <router-link
                        :to="{ name: 'MailRecuperarPassword' }"
                        class="btn-lg btn btn-link"
                        >Recuperar contraseña</router-link
                      >
                    </div>
                    <div class="float-right">
                      <button class="btn btn-primary btn-lg" type="submit">
                        Iniciar sesión
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="text-center text-white opacity-8 mt-3">
              Copyright © Mis Recibos Digitales 2021
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import FloatLabel from "@/components/layouts/FloatLabel";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  name: "Login",
  components: {
    FloatLabel,
  },
  data() {
    return {
      usuario: {},
      enviado: false,
    };
  },
  validations: {
    usuario: {
      cuil: { required },
      password: { required },
    },
  },
  computed: {
    logoClass: () => {
      if (window.location.hostname == "recibos.pampadev.com.ar") {
        return ' desarrollo ';
      } else if (window.location.hostname == "hma.misrecibosdigitales.com.ar") {
        return ' ima ';
      } else if (
        window.location.hostname == "isnsl.misrecibosdigitales.com.ar"
      ) {
        return ' isnsl ';
      } else if (
        window.location.hostname == "nsl.misrecibosdigitales.com.ar"
      ) {
        return ' isnsl ';
      } else if (
        window.location.hostname == "diocesis.misrecibosdigitales.com.ar"
      ) {
        return " diocesis ";
      } else if (
        window.location.hostname == "isjb.misrecibosdigitales.com.ar"
      ) {
        return " isjb ";
      } else if (
        window.location.hostname == "isjb.misrecibosdigitales.com.ar"
      ) {
        return " isjb ";
      } else {
        return ' desarrollo ';
      }
    },
    sistema: () => {
      if (window.location.hostname == "recibos.pampadev.com.ar") {
        return ' Testing ';
      } else if (window.location.hostname == "hma.misrecibosdigitales.com.ar") {
        return ' Hijas de María Auxiliadora ';
      } else if (
        window.location.hostname == "nsl.misrecibosdigitales.com.ar"
      ) {
        return ' Escuela Nuestra Señora de Luján ';
      } else if (
        window.location.hostname == "isnsl.misrecibosdigitales.com.ar"
      ) {
        return ' Institución salesiana Nuestra Señora de Luján ';
      } else if (
        window.location.hostname == "diocesis.misrecibosdigitales.com.ar"
      ) {
        return " Diocesis ";
      } else if (
        window.location.hostname == "isjb.misrecibosdigitales.com.ar"
      ) {
        return " Instituto San Juan Bosco ";
      } else {
        return 'Desarrollo';
      }
    },
  },
  mounted() {
    let title = document.querySelector("title");
    title.innerHTML = `Iniciar sesión - ${this.sistema}`;
    let descripcion = document.querySelector('meta[name="description"]');
    descripcion.content = `Inicia sesión para visualizar tus recibos digitales de ${this.sistema}`
  },
  methods: {
    ...mapActions({
      loginAction: "login",
    }),
    login() {
      this.enviado = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.loading = false;
        return false;
      }
      this.loginAction(this.usuario)
        .then((r) => {
          console.log(r);
          console.log(localStorage.getItem("token"));
          this.$router.push("/");
        })
        .catch((e) => {
          console.log(e);
          Swal.fire({
            icon: "error",
            title: "Oopss..",
            text: `El usuario y la contraseña no son validas.`,
          });
        });
    },
  },
};
</script>
